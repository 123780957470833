import { BehaviorSubject, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, scan, tap } from 'rxjs/operators';

export type FetchMode = 'cache-first' | 'network-only' | 'cache-only';

export function useNetwork(fetchMode: FetchMode) {
    return 'cache-first' === fetchMode || 'network-only' === fetchMode;
}

export function useCache(fetchMode: FetchMode) {
    return 'cache-first' === fetchMode || 'cache-only' === fetchMode;
}

interface CacheMap<T> {
    [key: string]: T | T[];
}

export class Cache<T> {

    private source = new Subject<CacheMap<T>>();

    private cache = new BehaviorSubject<CacheMap<T>>({});

    private subscription = from(this.source)
        .pipe(scan((previous, next) => ({ ...previous, ...next }), {}))
        .subscribe(this.cache);

    constructor(private idFn: (item: T) => string) {
    }

    replaceAll(items: T[]) {
        const cache = { all: items };

        items.forEach((item) => cache['id-' + this.idFn(item)] = item);

        this.source.next(cache);
    }

    add(item: T) {
        this.source.next({ ['id-' + this.idFn(item)]: item });
    }

    getAll(fetchMode: FetchMode, fetch: () => Observable<T[]>): Observable<T[]> {
        const result = new ReplaySubject<T[]>(1);
        const doUseCache = useCache(fetchMode);

        if (doUseCache) {
            this.cache
                .pipe(filter((cache) => 'undefined' !== typeof cache['all']))
                .subscribe((cache) => result.next(cache['all'] as T[]));
        }

        if (useNetwork(fetchMode)) {
            fetch()
                .pipe(tap((items) => this.replaceAll(items)))
                .subscribe(
                    (items) => doUseCache ? null : result.next(items),
                    (e) => result.error(e),
                    () => doUseCache ? null : result.complete(),
                );
        }

        return from(result);
    }

    get(id: string, fetchMode: FetchMode, fetch: () => Observable<T>): Observable<T> {
        const result = new ReplaySubject<T>(1);
        const doUseCache = useCache(fetchMode);

        if (doUseCache) {
            this.cache
                .pipe(
                    map((cache) => cache['id-' + id] as T),
                    filter((item) => 'undefined' !== typeof item),
                )
                .subscribe((item) => result.next(item));
        }

        if (useNetwork(fetchMode)) {
            fetch()
                .pipe(
                    tap((item) => this.add(item)),
                )
                .subscribe(
                    (item) => doUseCache ? null : result.next(item),
                    (e) => result.error(e),
                    () => doUseCache ? null : result.complete(),
                );
        }

        return from(result);
    }

    close() {
        this.subscription.unsubscribe();
    }

}
